export * from "./easy-translate";

import { getLangList } from "api";

import { loadStyle } from "utils/css";

import {
  translateAthena,
  translateDefault,
  translateShowtime,
  translateVenue,
  translateVogue,
  translateBrooklyn,
} from "./translate/index";

import { getTheme } from "utils";

const config = () => {
  if (!window.et_translate) {
    switch (getTheme()) {
      case "venue":
        translateVenue();
        break;
      case "showtime":
        translateShowtime();
        break;
      case "vogue":
        translateVogue();
        break;
      case "athena":
        translateAthena();
        break;
      case "brooklyn":
        translateBrooklyn();
        break;
      default:
        translateDefault();
        break;
    }
  }
};

const loadCss = () => {
  switch (getTheme()) {
    case "venue":
      loadStyle("venue");
      break;
    case "showtime":
      loadStyle("showtime");
      break;
    case "vogue":
      loadStyle("vogue");
      break;
    case "athena":
      loadStyle("athena");
      break;
    case "brooklyn":
      loadStyle("brooklyn");
      break;
    case "default":
      loadStyle("default");
    default:
      break;
  }
};

loadCss();

let time = 0;
const interval = setInterval(() => {
  //加载css
  time += 1;
  const isBrooklyn = getTheme() === "brooklyn";
  const checkVue = window.Vue;
  if (checkVue || isBrooklyn) {
    clearInterval(interval);
    const shop = window.location.hostname;
    getLangList(shop).then((res) => {
      if (res && res.code === 200) {
        let isOpen = res?.data?.app_status;
        if (isOpen) {
          checkVue ? window.Vue.nextTick(config) : config();
        }
      }
    });
  }

  if (time > 10) {
    clearInterval(interval);
  }
}, 500);
