import { getTranslate } from "api";
import { parseUrl, stringifyUrl } from "query-string";
import { TranslateOptions } from "types";
import { convertCodeToChar, tranferTextNodeToWordNode } from "utils";
import { getLangList } from "./api/index";
import Translate from "./translate";

interface EasyTranslateConfig extends TranslateOptions {
  mount?: HTMLElement | Document | Element;
  isMobile?: boolean;
}

type Language = { language: string; short: string; logo: string };
export default class EasyTranslate extends Translate {
  private mounted: boolean;
  private isOpen: boolean;
  private defaultLanguage: Language;
  private availableLanguages: Language[];
  public _currentLanguage: Language;
  private translated: { [lang: string]: boolean };
  app_status: boolean;

  constructor(options?: EasyTranslateConfig) {
    super(options || {});
    this.translated = {};
    this.defaultLanguage = { language: "English", short: "en", logo: "" };
    this._currentLanguage = { language: "English", short: "en", logo: "" };
    this.availableLanguages = [];
    this.isOpen = false;
    this.mounted = false;
    this.init(options);
    this.app_status = false;
  }

  public init(options?: EasyTranslateConfig) {
    const shop = window.location.hostname;
    getLangList(shop).then((res) => {
      if (res && res.code === 200) {
        if (res.data.app_status) {
          this.app_status = true;

          this.defaultLanguage = res.data.default;

          if (
            res.data.languages.some(
              (lang: Language) => lang.short === res.data.default.short
            )
          ) {
            // 翻译语言和默认语言一样的情况下展示未翻译的默认语言
            this.availableLanguages = res.data.languages.map((language) => {
              if (language.short === this.defaultLanguage.short) {
                return {
                  ...language,
                  language: this.defaultLanguage.language,
                };
              } else {
                return language;
              }
            });
          } else {
            this.availableLanguages = [res.data.default, ...res.data.languages];
          }

          //根据是否选择自动翻译设置默认语言
          const isChangeToDefultLang = res.data.change_default_lang;
          const short = localStorage.getItem("et-current");

          let {
            query: { lang },
          } = parseUrl(location.href);

          let urlLanguage = this.availableLanguages.find(
            (language) => language.short === lang
          );
          let localLanguage = this.availableLanguages.find(
            (lang) => lang.short === short
          );
          let chromeLanguage = this.availableLanguages.find(
            (lang) => lang.short === navigator.language.slice(0, 2)
          );

          if (urlLanguage) {
            this.currentLanguage = urlLanguage;
          }
          if (!urlLanguage && localLanguage) {
            this.currentLanguage = localLanguage;
          }
          if (
            !urlLanguage &&
            !localLanguage &&
            isChangeToDefultLang &&
            chromeLanguage
          ) {
            this.currentLanguage = chromeLanguage;
          }

          localStorage.setItem("et-current", this.currentLanguage.short);

          // 获取到数据后开始进行初始化UI界面，渲染预览选择列表
          this.initUi(options?.mount, options?.isMobile);

          //这里的请求转到了set currentLanguage 逻辑里面了
          this.observerDocumentChange();
        }
      } else {
        console.error("Get Translate Config Failed. Init Exited");
      }
    });
  }

  private makeUi() {
    const div = document.createElement("div");
    div.className = "et-select";
    const current = document.createElement("span");
    current.className = "et-current";
    current.innerHTML =
      `<img src="${this.currentLanguage.logo}" alt="logo" width='24' height='24'/><span>${this.currentLanguage.language}</span>`;

    const ul = document.createElement("ul");
    const list = this.availableLanguages
      .map(
        (lang) =>
          `<li data-short="${lang.short}" class="et-select-item ${
            this.currentLanguage.short === lang.short
              ? "et-select-item__selected"
              : ""
          }">
          <img src="${lang.logo}" alt="logo" width='24' height='24'/>
          ${lang.language}
          </li>`
      )
      .join("");

    // 给 ul 加个属性 unopened
    ul.setAttribute("unopened", "true");

    const open = () => {
      // 判断ul 的 unopened 属性，如果为true, 插入列表
      if (ul.getAttribute("unopened") === "true") {
        ul.innerHTML = list;
        ul.setAttribute("unopened", "false");
      }

      current.className = "et-current et-current-open";
      ul.style.display = "block";
      ul.style.opacity = "1";
      ul.style.top = "30px";
      this.isOpen = true;
      if(window.current_theme==='athena'){
        let headerLeftEl:HTMLDivElement|null=document.querySelector(".header-left");
        if(headerLeftEl){
          headerLeftEl.style.overflow='visible'
        }
      }
    };
    const close = () => {
      current.className = "et-current";
      ul.style.opacity = "0";
      ul.style.display = "none";
      ul.style.top = "0px";
      this.isOpen = false;
      if(window.current_theme==='athena'){
        let headerLeftEl:HTMLDivElement|null=document.querySelector(".header-left");
        if(headerLeftEl){
          headerLeftEl.style.overflow='hidden'
        }
      }
    };

    ul.addEventListener("click", (e) => {
      e.stopPropagation();
      const target = e.target as HTMLLIElement;
      if (target) {
        const short = target.dataset.short;

        const select = this.availableLanguages.find(
          (lang) => lang.short === short
        );

        if (select && select.short != this.currentLanguage.short) {
          this.currentLanguage = select;
          current.innerHTML =
            `<img src="${select.logo}" alt="logo" width='24' height='24'/><span>${select.language}</span>
            `;
        }

        for (let i = 0; i < ul.children.length; i++) {
          if (ul.children[i] === target) {
            ul.children[i].className =
              "et-select-item et-select-item__selected";
          } else {
            ul.children[i].className = "et-select-item";
          }
        }
      }
      close();
    });

    const currentEvent = (e) => {
      e.stopPropagation();
      if (this.isOpen) {
        close();
      } else {
        open();
      }
    };

    current.addEventListener("click", currentEvent);

    document.addEventListener("click", (e) => {
      e.stopPropagation();
      close();
    });

    div.appendChild(current);
    div.appendChild(ul);

    return div;
  }

  // 移动端UI
  private makeMobileUi() {
    const div = document.createElement("div");
    div.className = "et-select-mobile";
    const current = document.createElement("span");
    current.className = "et-current-mobile";

    // const stopScroll = (e) => {
    //   e.preventDefault();
    // };
    if (window.current_theme == "showtime") {
      current.innerHTML = this.currentLanguage.language;
    } else {
      current.innerHTML =
        `<img src="${this.currentLanguage.logo}" alt="logo" width='24' height='24'/>` +
        this.currentLanguage.language;
    }

    const mask = document.createElement("div");
    mask.className = "et-select-mobile__mask";
    mask.setAttribute(
      "style",
      "opacity: 0; visibility: hidden; z-index: 0; display: none;"
    );
    const ul = document.createElement("ul");
    const list = this.availableLanguages
      .map(
        (lang) =>
          `<li data-short="${lang.short}" class="et-select-item ${
            this.currentLanguage.short === lang.short
              ? "et-select-item__selected"
              : ""
          }">
          <img src="${lang.logo}" alt="logo" width='24' height='24'/>
          ${lang.language} (${lang.short.toUpperCase()})
          </li>`
      )
      .join("");

    ul.setAttribute("unopened", "true");

    const open = () => {
      // 判断ul 的 unopened 属性，如果为true, 插入列表
      if (ul.getAttribute("unopened") === "true") {
        ul.innerHTML = list;
        ul.setAttribute("unopened", "false");
      }

      document.body.style.overflow = "hidden";
      current.className = "et-current-mobile et-current-open";
      mask.style.visibility = "visible";
      mask.style.opacity = "1";
      mask.style.zIndex = "100002";
      mask.style.display = "block";

      this.isOpen = true;

      // if (window.current_theme === "default") {
      //   let headernaother: HTMLElement | null = document.querySelector('#carousel .headtitle');
      //   let header: HTMLElement | null = document.querySelector('.headbox');

      //   if (header) {
      //     header.style.zIndex = "100002";
      //   }
      //   if (headernaother) {
      //     headernaother.style.zIndex = "100002"
      //   }
      // }
      // else if (window.current_theme === "venue") {
      //   let header = document.querySelector('header');
      //   if (header) {
      //     header.style.zIndex = "100002"
      //   }
      // }
      if (window.current_theme === "showtime") {
        let header: HTMLElement | null = document.querySelector(
          "#header .mobile-nav-bar"
        );
        if (header) {
          header.style.zIndex = "100002";
        }
      }
      // else if (window.current_theme === "athena") {
      //   let header: HTMLElement | null = document.querySelector("#header");
      //   if (header) {
      //     header.style.zIndex = "100002";
      //   }
      // }
    };
    const close = () => {
      document.body.style.overflow = "visible";
      current.className = "et-current-mobile";
      mask.style.opacity = "0";
      mask.style.visibility = "hidden";
      mask.style.zIndex = "0";
      mask.style.display = "none";
      this.isOpen = false;
      if (window.current_theme === "showtime") {
        let header: HTMLElement | null = document.querySelector(
          "#header .mobile-nav-bar"
        );
        if (header) {
          header.style.zIndex = "2001";
        }
      }
    };

    ul.addEventListener("click", (e) => {
      e.stopPropagation();
      const target = e.target as HTMLLIElement;
      if (target) {
        const short = target.dataset.short;

        const select = this.availableLanguages.find(
          (lang) => lang.short === short
        );

        if (select && select.short != this.currentLanguage.short) {
          this.currentLanguage = select;
          if (window.current_theme == "showtime") {
            current.innerHTML = select.language;
          } else {
            current.innerHTML =
              `<img src=${select.logo} alt="logo" width='20' height='20' />` +
              select.language;
          }
        }

        for (let i = 0; i < ul.children.length; i++) {
          if (ul.children[i] === target) {
            ul.children[i].className =
              "et-select-item et-select-item__selected";
          } else {
            ul.children[i].className = "et-select-item";
          }
        }
      }
      //切换语言关闭蒙层
      let cancelBtn: HTMLElement | null = null;
      if (window.current_theme === "default") {
        cancelBtn = document.querySelector(
          "a[class='iconfont icon-cm-cancel-1-copy'][href='/']"
        );
      } else if (window.current_theme === "vogue") {
        cancelBtn = document.querySelector(
          "span[class='iconfont menu_icon_color vogue_menu_icon_color  icon-cm-cancel-1-copy']"
        );
      } else if (window.current_theme === "venue") {
        cancelBtn = document.querySelector(
          "span[data-dismiss='modal'][class='iconfont iconguanbi']"
        );
      } else if (window.current_theme === "athena") {
        cancelBtn = document.querySelector(
          "div[data-dismiss='modal'][class='modal-header']"
        );
      }
      if (cancelBtn) {
        cancelBtn.click();
      }
      close();
    });

    const currentEvent = (e) => {
      e.stopPropagation();
      if (this.isOpen) {
        close();
      } else {
        open();
      }
    };

    current.addEventListener("click", currentEvent);

    document.addEventListener("click", (e) => {
      e.stopPropagation();
      close();
    });
    div.appendChild(current);
    mask.appendChild(ul);
    div.appendChild(mask);

    return div;
  }

  // 观察dom发送改变
  private observerDocumentChange() {
    let bodyEl = document.querySelector("body");

    // MutationObserver 监听DOM 变动
    let appObserver = new MutationObserver((mutationList) => {
      const textNodes = this.getTextNodes();

      // 获取新的节点
      let newNodes = textNodes.filter((node: any) => {
        return (!node.node.et && !node.node?.parentNode?.et)
      });

      //newwordNodes这个类型就是allWords 的类型
      let newWordNodes = tranferTextNodeToWordNode(newNodes);

      //变成{t:,w:}[] 这种类型
      let newNodeWords = this.getTextFromWordNodes(newWordNodes);

      //屏蔽掉感谢页的追加销售计时器翻译
      let isUpsellChange = false;
      if (mutationList[0].target["className"]) {
        isUpsellChange =
          mutationList[0].target["className"] ===
          "upSelling-custom-options-global-timeout-1259";
      }
      if (newNodeWords.length && !isUpsellChange) {
        //翻译
        this.translate(newNodeWords.map((word) => word.w)).then((res) => {
          if (res.code === 200) {
            //改变改变了的元素的文字
            this.setTextFromApiData(
              res.data,
              this.currentLanguage.short,
              newWordNodes
            );
            this.translated[this.currentLanguage.short] = true;

            //把所有翻译过的值放到allwords里面
            let allWords = [...this.allWords, ...newWordNodes];
            this.allWords = allWords;
          }
        });
      }
    });

    if (bodyEl) {
      appObserver.observe(bodyEl, {
        attributes: false,
        childList: true,
        subtree: true,
      });
    }
  }

  private changeUrl(value: Language) {
    let parsedUrl = parseUrl(location.href);
    parsedUrl = {
      ...parsedUrl,
      query: {
        ...parsedUrl.query,
        lang: value.short,
      },
    };
    let url = stringifyUrl(parsedUrl);
    history.replaceState("null", "null", url);
  }

  /**
   * 初始化UI
   * @param container 
   * @param isMobile 是否移动端
   * @returns 
   */
  public initUi(container?: HTMLElement | Document | Element, isMobile?: boolean) {
    if (this.mounted) return;
    if (!container) {
      container = document.body;
    }

    if (isMobile) {
      container.appendChild(this.makeMobileUi());
    } else {
      container.appendChild(this.makeUi());
    }

    this.mounted = true;
  }

  set currentLanguage(value: Language) {
    localStorage.setItem("et-current", value.short);
    this._currentLanguage = value;
    this.changeUrl(value);
    this.switchTo();
  }

  get currentLanguage() {
    const short = localStorage.getItem("et-current");
    const urlShort = parseUrl(location.search).query.lang;
    if (urlShort) {
      try {
        const urlExist = this.availableLanguages.find(
          (lang) => lang.short === urlShort
        );
        if (urlExist) {
          return urlExist;
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (short) {
      try {
        const currentExist = this.availableLanguages.find(
          (lang) => lang.short === short
        );
        if (currentExist) {
          this.changeUrl(currentExist);
          return currentExist;
        }
      } catch (err) {
        console.error(err);
      }
    }
    this.currentLanguage = this.defaultLanguage;
    return this.defaultLanguage;
  }

  public switchTo() {
    if (!this.allWords.length) {
      //得到的结构为{node: text, words: 'ADD+', type: 1, properties: {…}}
      const textNodes = this.getTextNodes();

      //处理成0: text 下面有et这个属性
      const wordNodes = tranferTextNodeToWordNode(textNodes);
      this.allWords = wordNodes;
    }
    if (this.currentLanguage.short === this.defaultLanguage.short) {
      this.setTextNodes(this.allWords, this.defaultLanguage.short);
      return;
    }
    if (this.translated[this.currentLanguage.short]) {
      this.setTextNodes(this.allWords, this.currentLanguage.short);
      return;
    }
    //得到 {t: 3, w: 'puberty77'}[] 这样的数组结构
    const words = this.getTextFromWordNodes();

    this.translate(words.map((word) => word.w)).then((res) => {
      if (res.code === 200) {
        if (res.data.l_to) {
          this.setTextFromApiData(res.data, res.data.l_to);
        } else {
          this.setTextFromApiData(res.data);
        }
        this.translated[this.currentLanguage.short] = true;
      }
    });
  }

  public addNodes() {}

  public translate(words: string[]) {
    // set api request

    return getTranslate({
      request_url: window.location.href,
      l_from: this.defaultLanguage.short,
      l_to: this.currentLanguage.short,
      title: document.title,
      words,
    });
  }

  public setTextFromApiData(res, to?, words?) {
    if (!to) {
      to = this.currentLanguage.short;
    }
    if (!words) {
      words = this.allWords;
    }
    if (res && res.to_words && res.to_words.length) {
      const fromWords = res.words,
        toWords = res.to_words;
      for (let i = 0; i < words.length; i++) {
        var content = words[i].et.content || {};
        for (var j = 0; j < content.length; j++) {
          const { original, translations } = content[j];
          const wordsOrder = fromWords.indexOf(convertCodeToChar(original));
          if (wordsOrder !== -1) {
            if (!translations[to]) {
              const g = toWords[wordsOrder];
              if (g.replace) {
                g.replace(/et-(\d+)=""(\s*)\/(\s*)>/g, 'et-$1="">');
              }
              translations[to] = g;
            }
          }
        }
      }
    }
    try {
      this.setTextNodes(words, to);
    } catch (e) {
      console.error(e);
    }
  }
}
