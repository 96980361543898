import EasyTranslate from "src/easy-translate";
import { getIsMobile } from "utils/device";
const translateVenue = function () {
  const inOrderPge = new RegExp(/\/order?-\w+|orders\/\d+/).test(location.pathname);
  const mobileWidth = 767; // 移动端屏幕宽度临界值
  const isMobile = getIsMobile(mobileWidth); // 是否是移动端

  if (!isMobile) {
    const rightEl = document.querySelector(".header-trigger-right") || undefined;

    // 添加类 et-select-wrapper
    rightEl?.classList.add("et-select-wrapper");

    const div = document.createElement("div");

    div.setAttribute("style", "display:inline-flex;align-items:center;");

    if (rightEl) {
      rightEl?.prepend(div);
    }

    window.et_translate = new EasyTranslate({
      logVersion: false,
      enableMerge: false,
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".selectpricewrap",
        ".coupon-popover-content .count-down-content",
        "[class^=upSelling-custom-options-global-timeout]",
        "[v-cloak]",
        ".checkoutrightbody .shoppingcarlist .price",
        ".checkoutrightbody .computedlist",
        ".checkoutrightbody .computetotal",
        ".checkoutrightbody .computetotalTip span"
      ],
      mount: div,
      isMobile,
    });
  } else {
    // 兼容之后的升级，bootstrap 的 modal 会被替换成drawer 抽屉
    let appendEl = document.querySelector(".section_drawer #myModal .modal-content, .drawer-menu__content");

    if (appendEl) {
      window.et_translate = new EasyTranslate({
        logVersion: false,
        enableMerge: false,
        excluded_blocks: [
          ".et-select",
          ".et-select-mobile",
          ".selectpricewrap",
          ".coupon-popover-content .count-down-content",
          "[class^=upSelling-custom-options-global-timeout]",
          "[v-cloak]"
        ],
        mount: appendEl,
        isMobile,
      });
    }
  }

  // 怀疑订单页面重复挂载；TODO: 应优化
  if (inOrderPge) {
    const div = document.createElement("div");

    window.et_translate = new EasyTranslate({
      logVersion: false,
      enableMerge: false,
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".selectpricewrap",
        ".coupon-popover-content .count-down-content",
        "[class^=upSelling-custom-options-global-timeout]",
        "[v-cloak]"
      ],
      mount: div,
      isMobile,
    });
  }
};

export { translateVenue };
