import EasyTranslate from "src/easy-translate";
import { getIsMobile } from "utils/device";
function insertAfter(newNode, existingNode) {
  if (existingNode.nextSibling) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
  }
  else {
    existingNode.parentNode.appendChild(newNode);
  }
}
const translateAthena = function () {
  const mobileWidth = 767; // 移动端屏幕宽度临界值
  const isMobile = getIsMobile(mobileWidth)

  const inOrderPge = new RegExp(/\/order?-\w+|orders\/\d+/).test(
    location.pathname
  );
  if (!isMobile) {
    const beforeNode = document.querySelector(".header-top .header-left .menu");

    let div = document.createElement("div");
    div.setAttribute(
      "style",
      "padding-right:0;display:flex;align-items:center;margin-left:50px;min-width:140px;"
    );

    div.className = "et";
    if (beforeNode) {
      insertAfter(div, beforeNode);
    }

    window.et_translate = new EasyTranslate({
      logVersion: false,
      enableMerge: false,
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".selectpricewrap",
        ".coupon-popover-content .count-down-content",
        "[class^=upSelling-custom-options-global-timeout]",
        ".header-right .nav-langs",
        "[v-cloak]",
        ".checkoutrightbody .shoppingcarlist .price",
        ".checkoutrightbody .computedlist",
        ".checkoutrightbody .computetotal",
        ".checkoutrightbody .computetotalTip span"
      ],
      extra_definitions: [
        {
          type: 13,
          selector: "[data-title]",
          attribute: "data-title",
        },
        {
          type: 14,
          selector: "[data-description]",
          attribute: "data-description",
        },
      ],
      mount: div,
      isMobile
    });
  } else {
    // const containerBox = document.querySelector('.header-top .header-left') || undefined;
    // let div = document.createElement('div')
    // div.setAttribute('style', 'padding-left:10px;padding-right:10px;')

    // //调整logo区域的显示区域，保证砸门的插件显示不被遮挡
    // const logoEl = document.querySelector("#header h1");
    // logoEl?.setAttribute("style", "max-width:20%")

    let containerBox = document.querySelector(".section_drawer .menu-slide-out, .drawer-menu__content .menu-slide-out");
    let li = document.createElement("li");
    if (containerBox) {
      containerBox.appendChild(li);
      window.et_translate = new EasyTranslate({
        logVersion: false,
        enableMerge: false,
        excluded_blocks: [
          ".et-select",
          ".et-select-mobile",
          ".selectpricewrap",
          ".coupon-popover-content .count-down-content",
          "[class^=upSelling-custom-options-global-timeout]",
          ".header-right .nav-langs",
          "[v-cloak]"
        ],
        mount: li,
        isMobile
      });
    }
  }
  if (inOrderPge) {
    let div = document.createElement("div");

    window.et_translate = new EasyTranslate({
      logVersion: false,
      enableMerge: false,
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".selectpricewrap",
        ".coupon-popover-content .count-down-content",
        "[class^=upSelling-custom-options-global-timeout]",
        ".header-right .nav-langs",
        "[v-cloak]"
      ],
      extra_definitions: [
        {
          type: 13,
          selector: "[data-title]",
          attribute: "data-title",
        },
        {
          type: 14,
          selector: "[data-description]",
          attribute: "data-description",
        },
      ],
      mount: div,
      isMobile
    });
  }
};
export { translateAthena };
